
import React, { useState, useRef } from "react";
import axios from 'axios';
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select, { components } from "react-select";
import ReCAPTCHA from "react-google-recaptcha";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const schema = yup.object().shape({
    name: yup.string().trim().required("Name is required"),
    email: yup.string().trim().email("Invalid email format").required("Email is required"),
    gender: yup.string().trim().required("Gender is required"),
    date: yup.string().trim().required("DOB is required"),
    age: yup
        .number()
        .required("Age is required")
        .typeError("Age is required")
        .min(1, "Age must be at least 1")
        .max(100, "Age cannot be more than 100"),
    address: yup.string().trim().required("Address is required"),
    occupation: yup.string().trim().required("Occupation is required"),
    education: yup.string().trim().required("Educational qualification is required"),
    nationality: yup.string().trim().required("Nationality is required"),
    country: yup.string().trim().required("Country is required"),
    city: yup.string().trim().required("City is required"),
    phone: yup
      .string()
      .trim()
      .required("Contact Number is required")
      .test("is-valid-phone", "Contact Number is invalid", (value) => {
        const phoneNumber = value.replace(/[^0-9]/g, "");
        return phoneNumber.length >= 10 && phoneNumber.length <= 15;
      }),
    subjectOfInterest: yup
        .array()
        .of(yup.string().trim().required("Please select your interest"))
        .min(1, "Please select at least one subject of interest"),
    durationOptions: yup
        .array()
        .of(yup.string().trim().required("Please select duration"))
        .min(1, "Please select at least one duration of interest"),
    nationalId: yup
        .mixed()
        .required("Nationality Id is required and should be less than 10MB")
        .test("fileSize", "Nationality Id is required and should be less than 10MB", (value) => {
            return value && value[0] && value[0].size <= 10485760; // 10MB
        }),
    paymentSC: yup
        .mixed()
        .required("Screenshot of payment is required and should be less than 10MB")
        .test("fileSize", "Screenshot of payment is required and should be less than 10MB", (value) => {
            return value && value[0] && value[0].size <= 10485760; // 10MB
        }),
    terms: yup.bool().oneOf([true], "You must accept the terms and conditions"),
    privacy: yup.bool().oneOf([true], "You must accept the privacy policy")
});

const CustomOption = (props) => {
    return (
        <components.Option {...props}>
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
            />{" "}
            <label>{props.label}</label>
        </components.Option>
    );
};

const AdmissionForm = () =>{

    const recaptchaRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [nationalIdPreview, setNationalIdPreview] = useState(null);
    const [paymentScPreview, setPaymentScPreview] = useState(null);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setError,
        clearErrors
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            email: '',
            gender: '',
            date: '',
            age: '',
            address: '',
            occupation: '',
            education: '',
            messageForTrainer: '',
            nationality : '',
            country : '',
            city : '',
            goal : '',
            howToKnow: '',
            phone: '+91',
            subjectOfInterest: [],
            durationOptions: [],
            nationalId: null,
            paymentSC: null,
            terms: false,
            privacy: false
        }
    });

    const onSubmit = async (data) => {

        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            setError("recaptcha", {
                type: "manual",
                message: "Please complete the reCAPTCHA"
            });
            return;
        }
        setIsLoading(true);

        try {
            const formData = new FormData();
            Object.keys(data).forEach(key => {
                if (key === 'nationalId' || key === 'paymentSC') {
                    formData.append(key, data[key][0]);
                } else if (key === 'subjectOfInterest' || key === 'durationOptions') {
                    data[key].forEach(value => formData.append(`${key}[]`, value));
                } else {
                    formData.append(key, data[key]);
                }
            });
            formData.append('recaptcha', recaptchaValue);

            const response = await axios.post('https://www.harshitamusicacademy.com/adminPanel/API/admission-form-send.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                setShowPopup(true);
                reset({ 
                    name: '',
                    email: '',
                    gender: '',
                    date: '',
                    age: '',
                    address: '',
                    occupation: '',
                    education: '',
                    messageForTrainer: '',
                    nationality : '',
                    country : '',
                    city : '',
                    goal : '',
                    howToKnow: '',
                    phone: '+91',
                    subjectOfInterest: [],
                    durationOptions: [],
                    nationalId: null,
                    paymentSC: null,
                    terms: false,
                    privacy: false
                });
                setNationalIdPreview(null);
                setPaymentScPreview(null);
                recaptchaRef.current.reset();
            } else {
                console.error('Failed to send message:', response.data);
            }
        } catch (error) {
            console.error('Error sending form data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRecaptchaChange = (value) => {
        if (value) {
            clearErrors("recaptcha");
        }
    };

    const handleNationalIdChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const preview = URL.createObjectURL(file);
            setNationalIdPreview(preview);
            clearErrors("nationalId");
        } else {
            setNationalIdPreview(null);
        }
    };

    const handlePaymentScChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const preview = URL.createObjectURL(file);
            setPaymentScPreview(preview);
            clearErrors("paymentSC");
        } else {
            setPaymentScPreview(null);
        }
    };

    const SubjectOptions = [
        { value: "Opera", label: "Opera" },
        { value: "Western Vocals", label: "Western Vocals" },
        { value: "Indian Classical Vocals", label: "Indian Classical Vocals" },
        { value: "Bollywood Film Singing", label: "Bollywood Film Singing" },
        { value: "Playback Stage Singing", label: "Playback Stage Singing" },
        { value: "Acoustic Guitar", label: "Acoustic Guitar" },
        { value: "Electric Guitar", label: "Electric Guitar" },
        { value: "Violin", label: "Violin" },
        { value: "Keyboard", label: "Keyboard" },
        { value: "Piano", label: "Piano" },
        { value: "Drums", label: "Drums" },
        { value: "All-In-One-Course", label: "All In One Course" }
    ];

    const DurationOptions = [
        { value: "Crash Course (10 Session) - Beginner", label: "Crash Course (10 Session) - Beginner" },
        { value: "Quarter Course (3 Month) - Beginner", label: "Quarter Course (3 Month) - Beginner" },
        { value: "Certification Course (6 Month) - Beginner", label: "Certification Course (6 Month) - Beginner" },
        { value: "Crash Course (10 Session) - Intermediate", label: "Crash Course (10 Session) - Intermediate" },
        { value: "Quarter Course (3 Month) - Intermediate", label: "Quarter Course (3 Month) - Intermediate" },
        { value: "Certification Course (6 Month) - Intermediate", label: "Certification Course (6 Month) - Intermediate" },
        { value: "Crash Course (10 Session) - Advance Course", label: "Crash Course (10 Session) - Advance Course" },
        { value: "Quarter Course (3 Month) - Advance Course", label: "Quarter Course (3 Month) - Advance Course" },
        { value: "Certification Course (6 Month) - Advance Course", label: "Certification Course (6 Month) - Advance Course" },
        { value: "Virtuoso Course", label: "Virtuoso Course" },
        { value: "All in One Course", label: "All in One Course" },
        { value: "Group Class", label: "Group Class" }
    ];

    return(
        <>
        <form method='POST' onSubmit={handleSubmit(onSubmit)}>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="name">Full Name <span className="LabelReq">*</span></label>
                    <input type="text" id="name" name="name" className="namebox" {...register("name")} />
                    {errors.name && <span className="errorForm">{errors.name.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="email">Email Address <span className="LabelReq">*</span></label>
                    <input type="text" id="email" name="email" className="namebox" {...register("email")} />
                    {errors.email && <span className="errorForm">{errors.email.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="contact">Contact Number <span className="LabelReq">*</span></label>
                    <Controller
                        id="contact"
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <PhoneInput
                                country={"in"}
                                value={value}
                                onChange={onChange}
                                inputClass="namebox"
                                inputStyle={{ width: "100%" }}
                                specialLabel=""
                                enableSearch
                                disableSearchIcon
                                searchPlaceholder="Search"
                                renderStringAsHTML
                                dropdownStyle={{
                                    width: '300px',
                                    textAlign: 'left'
                                }}
                                containerClass="phone-input-container"
                                buttonClass="phone-input-button"
                                dropdownClass="phone-input-dropdown"
                            />
                        )}
                    />
                    {errors.phone && <span className="errorForm">{errors.phone.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="gender">Gender <span className="LabelReq">*</span></label>
                    <div className="genderInputs">
                        <input type="radio" id="male" name="gender" value="male" className="namebox" {...register("gender")} />
                        <label htmlFor="male" className="genderSelect">Male</label>
                    </div>
                    <div className="genderInputs">
                        <input type="radio" id="female" name="gender" value="female" className="namebox" {...register("gender")} />
                        <label htmlFor="female" className="genderSelect">Female</label>
                    </div>
                    <div className="genderInputs">
                        <input type="radio" id="other" name="gender" value="other" className="namebox" {...register("gender")} />
                        <label htmlFor="other" className="genderSelect">Other</label>
                    </div>
                    {errors.gender && <span className="errorForm">{errors.gender.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="dob">DOB <span className="LabelReq">*</span></label>
                    <input type="date" id="dob" name="date" className="namebox" {...register("date")} max={new Date().toISOString().split("T")[0]} />
                    {errors.date && <span className="errorForm">{errors.date.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="age">Your Age <span className="LabelReq">*</span></label>
                    <input type="number" id="age" name="age" className="namebox" {...register("age")} />
                    {errors.age && <span className="errorForm">{errors.age.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="address">Residential Address <span className="LabelReq">*</span></label>
                    <input type="text" id="address" name="address" className="namebox" {...register("address")} />
                    {errors.address && <span className="errorForm">{errors.address.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="country">Country <span className="LabelReq">*</span></label>
                    <input type="text" id="country" name="country" className="namebox" {...register("country")} />
                    {errors.country && <span className="errorForm">{errors.country.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="city">City <span className="LabelReq">*</span></label>
                    <input type="text" id="city" name="city" className="namebox" {...register("city")} />
                    {errors.city && <span className="errorForm">{errors.city.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="nationality">Nationality <span className="LabelReq">*</span></label>
                    <input type="text" id="nationality" name="nationality" className="namebox" {...register("nationality")} />
                    {errors.nationality && <span className="errorForm">{errors.nationality.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="occupation">Current Occupation <span className="LabelReq">*</span></label>
                    <input type="text" id="occupation" name="occupation" className="namebox" {...register("occupation")} />
                    {errors.occupation && <span className="errorForm">{errors.occupation.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="qualification">Educational Qualification <span className="LabelReq">*</span></label>
                    <input type="text" id="qualification" name="education" className="namebox" {...register("education")} />
                    {errors.education && <span className="errorForm">{errors.education.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="interests">Subjects Of Your Interest <span className="LabelReq">*</span></label>
                    <Controller
                        id="interests"
                        name="subjectOfInterest"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Select
                                isMulti
                                options={SubjectOptions}
                                value={SubjectOptions.filter(option => value.includes(option.value))}
                                onChange={(selected) => {
                                    onChange(selected ? selected.map(option => option.value) : []);
                                }}
                                components={{ Option: CustomOption }}
                                className="namebox"
                                classNamePrefix="select"
                                placeholder="Select Subjects Of Interest"
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                            />
                        )}
                    />
                    {errors.subjectOfInterest && <span className="errorForm">{errors.subjectOfInterest.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="duration">WHAT COURSE DO YOU CHOOSE TO ENROLL? <span className="LabelReq">*</span></label>
                    <Controller
                        id="duration"
                        name="durationOptions"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Select
                                isMulti
                                options={DurationOptions}
                                value={DurationOptions.filter(option => value.includes(option.value))}
                                onChange={(selected) => {
                                    onChange(selected ? selected.map(option => option.value) : []);
                                }}
                                components={{ Option: CustomOption }}
                                className="namebox"
                                classNamePrefix="select"
                                placeholder="Select the duration"
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                            />
                        )}
                    />
                    {errors.durationOptions && <span className="errorForm">{errors.durationOptions.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="training">HAVE  TAKEN ANY PROFESSIONAL TRAINING? IF YES, PLEASE MENTION WHICH COURSE, TUTOR/INSTITUTION NAME, TIME DURATION, SUBJECT AND OTHER NECESSARY DETAILS.  <span className="LabelReq">*</span></label>
                    <textarea type="text" id="training" rows="5" name="messageForTrainer" className="namebox" {...register("messageForTrainer")}></textarea>
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="learning">WHAT IS YOUR GOAL FOR LEARNING MUSIC? <span className="LabelReq">*</span></label>
                    <textarea type="text" id="learning" rows="5" name="goal" className="namebox" {...register("goal")}></textarea>
                    {errors.goal && <span className="errorForm">{errors.goal.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="about">HOW DID YOU GET TO KNOW ABOUT US? <span className="LabelReq">*</span></label>
                    <textarea type="text" id="about" rows="5" name="howToKnow" className="namebox" {...register("howToKnow")}></textarea>
                    {errors.howToKnow && <span className="errorForm">{errors.howToKnow.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="nationalId">Kindly add the nationality Id <span className="LabelReq">*</span></label>
                    <input type="file" id="nationalId" name="nationalId" className="namebox" {...register("nationalId")} accept="image/*" onChange={handleNationalIdChange} />
                    {nationalIdPreview && (
                        <div>
                            <img className="imgPreview" src={nationalIdPreview} alt="National Id Preview" />
                        </div>
                    )}
                    {errors.nationalId && <span className="errorForm">{errors.nationalId.message}</span>}
                </div>
            </div>
            <div className="inputTypesBoxes wow fadeInUp">
                <div className="inputtypes">
                    <label htmlFor="paymentSc">KINDLY ADD PAYMENT SCREENSHOT OF TUITION FEE (THE TUITION FEE IS MENTIONED IN THE COURSE PAGE, OR GET IN TOUCH WITH YOUR COUNSELLOR FOR MORE CLARIFICATION ON FEE STRUCTURE) <span className="LabelReq">*</span></label>
                    <input type="file" id="paymentSc" name="paymentSC" className="namebox" {...register("paymentSC")} accept="image/*" onChange={handlePaymentScChange} />
                    {paymentScPreview && (
                        <div>
                            <img className="imgPreview" src={paymentScPreview} alt="Payment Screenshot Preview" />
                        </div>
                    )}
                    {errors.paymentSC && <span className="errorForm">{errors.paymentSC.message}</span>}
                </div>
            </div>

            <div className="admissionFormT&C wow fadeInUp">
                <div className="inputtypes">
                    <div className="termsCheckedLabel">
                        <input type="checkbox" id="terms" name="terms" {...register("terms")} />
                        <label htmlFor="terms" className="checkedT&C">I have read and agreed to all the terms and conditions<span className="LabelReq">*</span></label>
                    </div>
                    {errors.terms && <span className="errorForm">{errors.terms.message}</span>}
                </div>
                <div className="inputtypes">
                    <div className="termsCheckedLabel">
                        <input type="checkbox" id="privacy" name="privacy" {...register("privacy")} />
                        <label htmlFor="privacy" className="checkedT&C">I have read and agreed to all the privacy policy<span className="LabelReq">*</span></label>
                    </div>
                    {errors.privacy && <span className="errorForm">{errors.privacy.message}</span>}
                </div>
            </div>

            <div className="inputtypes">
                <ReCAPTCHA
                    ref={recaptchaRef}
                    // sitekey="6LcfUJYlAAAAAMZKLnki7CIqzNT2CjtmcNaLYfKa"    // localhost
                    // sitekey="6LeeE_MpAAAAAMNnk2LK58Gr-ELSPIrHiW0zmQRM"       // live site harshitamusicacademy.com
                    sitekey="6Lfv4g8qAAAAAFDtjZ4GUKk3uVx64RMAQn7o8WLF"       // live site harshitaschoolofmusic.com
                    onChange={handleRecaptchaChange}
                />
                {errors.recaptcha && <span>{errors.recaptcha.message}</span>}
            </div>

            <div className='submitform wow fadeInUp'>
                <input type="submit" className='submit' value='SUBMIT' />
            </div>
        </form>

        {showPopup && (
            <div className="formOverlay">
                <div className="popup">
                    <div className='popup-check-right'>
                        <i className='fa fa-check'></i>
                    </div>
                    <p>We'll get back to you regarding admission confirmation as soon as possible.</p>
                    <button className="close_btn" onClick={() => setShowPopup(false)}>X</button>
                </div>
            </div>
        )}
        {isLoading && (
            <div className='loading-overlay'>
                <div className="loading-spinner">
                    <img src={`${fileUrl}/loading.gif`} alt="Loading..." />
                </div>
            </div>
        )}
        </>
    )
}

export default AdmissionForm;