
import React, { useState, useRef } from "react";
import axios from 'axios';
import { format } from 'date-fns';
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimezoneSelect from "react-timezone-select";
import ReCAPTCHA from "react-google-recaptcha";

const fileUrl = "https://www.harshitamusicacademy.com/files";

const schema = yup.object().shape({
    name: yup.string().trim().required("Name is required"),
    email: yup.string().trim().email("Invalid email format").required("Email is required"),
    country: yup.string().trim().required("Country is required"),
    city: yup.string().trim().required("City is required"),
    phone: yup
      .string()
      .trim()
      .required("Contact Number is required")
      .test("is-valid-phone", "Contact Number is invalid", (value) => {
        const phoneNumber = value.replace(/[^0-9]/g, "");
        return phoneNumber.length >= 10 && phoneNumber.length <= 15;
      }),
    userRole: yup.string().trim().required("Please select, who are you ?"),
    subjectOfInterest: yup
        .array()
        .of(yup.string().trim().required("Please select your interest"))
        .min(1, "Please select at least one subject of interest"),
    demoDateTime: yup.date().required("Please select a date and time"),
    timeZone: yup.string().trim().required("Please select your time zone"),
    message: yup.string().trim().required("Please enter your message note"),
});

const CustomOption = (props) => {
    return (
        <components.Option {...props}>
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
            />{" "}
            <label>{props.label}</label>
        </components.Option>
    );
};

const BookDemoForm = () =>{

    const recaptchaRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTimeZone, setSelectedTimeZone] = useState({});

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
        setError,
        clearErrors
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            email: '',
            country : '',
            city : '',
            phone: '+91',
            userRole: '',
            subjectOfInterest: [],
            demoDateTime: null,
            demoDateTimeNext: null,
            timeZone: '',
            message: ''
        }
    });

    const onSubmit = async (data) => {
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            setError("recaptcha", {
                type: "manual",
                message: "Please complete the reCAPTCHA"
            });
            return;
        }
        setIsLoading(true);

        const formattedDemoDateTime = format(data.demoDateTime, "MM/dd/yyyy, hh:mm a");
        const formattedDemoDateTimeNext = data.demoDateTimeNext ? format(data.demoDateTimeNext, "MM/dd/yyyy, hh:mm a") : null;

        const formData = {
            ...data,
            demoDateTime: formattedDemoDateTime,
            demoDateTimeNext: formattedDemoDateTimeNext,
        };

        console.log(formData);
        
        try {
            const response = await axios.post('https://www.harshitamusicacademy.com/adminPanel/API/bookdemo-form-send.php', {
                formdata: formData,
                recaptcha: recaptchaValue
            });

            if (response.status === 200) {
                setShowPopup(true);
                reset({ 
                    name: '',
                    email: '',
                    country : '',
                    city : '',
                    phone: '+91',
                    userRole: '',
                    subjectOfInterest: [],
                    demoDateTime: null,
                    demoDateTimeNext: null,
                    timeZone: '',
                    message: ''
                });
                recaptchaRef.current.reset();
            } else {
                console.error('Failed to send message:', response.data);
            }
        } catch (error) {
            console.error('Error sending form data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRecaptchaChange = (value) => {
        if (value) {
            clearErrors("recaptcha");
        }
    };

    const options = [
        { value: "Opera", label: "Opera" },
        { value: "Western Vocals", label: "Western Vocals" },
        { value: "Indian Classical Vocals", label: "Indian Classical Vocals" },
        { value: "Bollywood Film Singing", label: "Bollywood Film Singing" },
        { value: "Playback Stage Singing", label: "Playback Stage Singing" },
        { value: "Acoustic Guitar", label: "Acoustic Guitar" },
        { value: "Electric Guitar", label: "Electric Guitar" },
        { value: "Violin", label: "Violin" },
        { value: "Keyboard", label: "Keyboard" },
        { value: "Piano", label: "Piano" },
        { value: "Drums", label: "Drums" },
        { value: "All-In-One-Course", label: "All In One Course" }  
    ];

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 3
        })
    };

    return(
        <>
        <form method='POST' onSubmit={handleSubmit(onSubmit)}>
            <div className="inputTwoFieldsAln">
                <div className="inputtypes wow fadeInUp">
                    <input type="text" name="name" placeholder="Name" className="namebox" {...register("name")} />
                    {errors.name && <span>{errors.name.message}</span>}
                </div>
                <div className="inputtypes wow fadeInUp">
                    <input type="text" name="email" placeholder="Email" className="namebox" {...register("email")} />
                    {errors.email && <span>{errors.email.message}</span>}
                </div>
            </div>
            <div className="inputTwoFieldsAln">
                <div className="inputtypes wow fadeInUp">
                    <input type="text" name="country" placeholder="Country" className="namebox" {...register("country")} />
                    {errors.country && <span>{errors.country.message}</span>}
                </div>
                <div className="inputtypes wow fadeInUp">
                    <input type="text" name="city" placeholder="City" className="namebox" {...register("city")} />
                    {errors.city && <span>{errors.city.message}</span>}
                </div>
            </div>
            <div className="inputTwoFieldsAln">
                <div className="inputtypes wow fadeInUp">
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <PhoneInput
                                country={"in"}
                                value={value}
                                onChange={onChange}
                                inputClass="namebox"
                                inputStyle={{ width: "100%" }}
                                specialLabel=""
                                enableSearch
                                disableSearchIcon
                                searchPlaceholder="Search"
                                renderStringAsHTML
                                dropdownStyle={{
                                    width: '300px',
                                    textAlign: 'left'
                                }}
                                containerClass="phone-input-container"
                                buttonClass="phone-input-button"
                                dropdownClass="phone-input-dropdown"
                            />
                        )}
                    />
                    {errors.phone && <span>{errors.phone.message}</span>}
                </div>
                <div className="inputtypes wow fadeInUp">
                    <select {...register("userRole")} defaultValue="">
                        <option value="" disabled>You Are</option>
                        <option value="Parent/Guardian">Parent/Guardian</option>
                        <option value="Student">Student</option>
                    </select>
                    {errors.userRole && <span>{errors.userRole.message}</span>}
                </div>
            </div>

            <div className="inputTwoFieldsAln">
                <div className="inputtypes wow fadeInUp">
                    <Controller
                        name="demoDateTime"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                selected={value}
                                onChange={onChange}
                                showTimeSelect
                                dateFormat="MM/dd/yyyy, hh:mm a"
                                className="namebox"
                                placeholderText="Choose 1st preffered date & time for the Demo"
                            />
                        )}
                    />
                    {errors.demoDateTime && <span>{errors.demoDateTime.message}</span>}
                </div>
                <div className="inputtypes wow fadeInUp">
                    <Controller
                        name="demoDateTimeNext"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                selected={value}
                                onChange={onChange}
                                showTimeSelect
                                dateFormat="MM/dd/yyyy, hh:mm a"
                                className="namebox"
                                placeholderText="Choose 2nd preffered date & time for the Demo"
                            />
                        )}
                    />
                    {errors.demoDateTimeNext && <span>{errors.demoDateTimeNext.message}</span>}
                </div>
            </div>
            <div className="inputtypes wow fadeInUp">
                <Controller
                    name="timeZone"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <TimezoneSelect
                            value={value}
                            onChange={(newTimeZone) => {
                                onChange(newTimeZone.value);
                                setSelectedTimeZone(newTimeZone);
                            }}
                            className="namebox"
                            placeholder="Select Time Zone"
                            styles={customStyles}
                        />
                    )}
                />
                {errors.timeZone && <span>{errors.timeZone.message}</span>}
            </div>
            
            <div className="inputtypes wow fadeInUp">
                <Controller
                    name="subjectOfInterest"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Select
                            isMulti
                            options={options}
                            value={options.filter(option => value.includes(option.value))}
                            onChange={(selected) => {
                                onChange(selected ? selected.map(option => option.value) : []);
                            }}
                            components={{ Option: CustomOption }}
                            className="namebox"
                            classNamePrefix="select"
                            placeholder="Subjects Of Interest"
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            styles={customStyles}
                        />
                    )}
                />
                {errors.subjectOfInterest && <span>{errors.subjectOfInterest.message}</span>}
            </div>

            <div className="inputtypes wow fadeInUp">
                <textarea type="text" rows="5" name="message" placeholder="Message" className="namebox" {...register("message")} />
                {errors.message && <span>{errors.message.message}</span>}
            </div>

            <div className="inputtypes">
                <ReCAPTCHA
                    ref={recaptchaRef}
                    // sitekey="6LcfUJYlAAAAAMZKLnki7CIqzNT2CjtmcNaLYfKa"    // localhost
                    // sitekey="6LeeE_MpAAAAAMNnk2LK58Gr-ELSPIrHiW0zmQRM"       // live site harshitamusicacademy.com
                    sitekey="6Lfv4g8qAAAAAFDtjZ4GUKk3uVx64RMAQn7o8WLF"       // live site harshitaschoolofmusic.com
                    onChange={handleRecaptchaChange}
                />
                {errors.recaptcha && <span>{errors.recaptcha.message}</span>}
            </div>
            <div className='submitform wow fadeInUp'>
                <input type="submit" className='submit' value='SUBMIT' />
            </div>
        </form>
        {showPopup && (
            <div className="formOverlay">
                <div className="popup">
                    <div className='popup-check-right'>
                        <i className='fa fa-check'></i>
                      </div>
                    <p>We’ll get back to you regarding demo confirmation as soon as possible.</p>
                    <button className="close_btn" onClick={() => setShowPopup(false)}>X</button>
                </div>
            </div>
        )}
        {isLoading && (
            <div className='loading-overlay'>
                <div className="loading-spinner">
                    <img src={`${fileUrl}/loading.gif`} alt="Loading..." />
                </div>
            </div>
        )}
        </>
    )
}

export default BookDemoForm;